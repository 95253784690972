import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueI18n from 'vue-i18n';
import env from './env';
import translations from './services/translations.service';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
import './bootstrap.min.css';
import './dialog-extra.css';
import './dialog.css';
import './slick.css';
import './slick-theme.css';
import './main.css';
import './btneff.css';
import './font-awesome.min.css';
import '@/css/themes/helki.css';
import 'HelkiThemeCSS';
import './svg-fonts.css';
import './rzslider.css';
import './icheck-bootstrap.min.css';
import { AmplitudeService } from './services/amplitude.service';
import { UserService } from './services/user.service';
import { ApiService } from './services/api.service';
import { SessionService } from './services/session.service';
import { DevListService } from './services/devList.service';
import { helkiNodeManagement } from './services/helkiNodeManagement.service';
import { version } from './services/version';
import { ConfigService } from './services/config';
import { geolocation } from './services/geolocation';
import Slick from 'vue-slick';
import 'onsenui/css/onsenui.css';
import 'onsenui/css/onsen-css-components.css';
import VueOnsen from 'vue-onsenui';
import * as VueGoogleMaps from 'vue2-google-maps';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import * as Sentry from '@sentry/vue';
import 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import 'vue2-datepicker';
(window as any).ProgressBar = require('progressbar.js');
import "reinvented-color-wheel/css/reinvented-color-wheel.min.css";

window.addEventListener('deviceready', function() {
  (window as any).IonicDeeplink.route({
    '/': {
      target: '',
      parent: '',
    }
  }, function (match: any) {
    alert('Got match');
  }, function (nomatch: any) {
    alert('Got no match');
  });
});

if (env.development.cordovaMock) {
  (window as any).cordova = {};
  (window as any).bluetoothle = {
    stopScan: () => 0,
  };
  (window as any).wifiPlugin = {
    setAPIPort: (apiPort: number, callback: any) => callback(),
    setNetworkPrefix: (prefix: string, callback: any) => callback(),
    waitForDeviceConnection: (devId: string, callback: any) => callback(),
    getDeviceInfo: (data: any, callback: any) => setTimeout(() => callback(`{"devid": "${env.development.mockedDevId}"}`), 5000),
    cancel: () => 0,
    setWifiNetwork: (networkData: any, callback: any) => callback(),
  };
}

if (env.interface.maxItemsOnCarousel !== undefined) {
  store.commit('setMaxItemsOnCarousel', Number(env.interface.maxItemsOnCarousel));
}
store.commit('setOnlyReadyNodes', env.interface.onlyReadyNodes);

Sentry.init({
  Vue,
  dsn: env.sentry.dsn,
});

Vue.use(Toast, {
  transition: 'Vue-Toastification__fade',
  hideProgressBar: true,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: env.maps.apiKey,
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
});

VueOnsen.platform.select('ios');
Vue.use(VueOnsen);

Vue.use(Slick as any);

Vue.filter('decimal', function (value: string) {
  const number = Number(value);
  let string = (number - Math.floor(number)).toFixed(1);
  string = string.substring(1);
  if (string === '.0') {
      return '';
  }
  return string;
});

Vue.filter('integer', function (value: any) {
  return parseInt(value, 10) + '';
});

Vue.filter('power', function (power: any) {
  return isNaN(power) ? '---' : `${power} (W)`;
})

Vue.filter('dayMinuteToTime', function (value: string) {
  const hour = Math.floor(Number(value) / 60);
  const minute = Number(value) - hour * 60;

  let string = '';

  string += ((hour < 10) ? ('0' + hour) : hour);
  string += ':';
  string += ((minute < 10) ? ('0' + minute) : minute);

  return string;
});

Vue.config.productionTip = false;

// Create i18n instance
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: translations.locale,
  fallbackLocale: translations.fallbackLocale,
  messages: translations.messages,
  dateTimeFormats: translations.dateTimeFormats,
});

// Event hub plugin (for global events)
const EventHubPlugin = (V: typeof Vue): void => {
  V.prototype.$eventHub = new Vue();
};
Vue.use(EventHubPlugin);

const sessionService = new SessionService(store, env.info);
const SessionServicePlugin = (V: typeof Vue): void => {
  V.prototype.$sessionService = sessionService;
};
Vue.use(SessionServicePlugin);

const configService = new ConfigService({ host: env.api.host });
const ConfigServicePlugin = (V: typeof Vue): void => {
  V.prototype.$configService = configService;
};
Vue.use(ConfigServicePlugin);

const versionService = new version(sessionService, configService);
const VersionServicePlugin = (V: typeof Vue): void => {
  V.prototype.$versionService = versionService;
};
Vue.use(VersionServicePlugin);

// Create API service
const apiService = new ApiService(env.api, sessionService, configService, store, router);
const ApiServicePlugin = (V: typeof Vue): void => {
  V.prototype.$apiService = apiService;
};
Vue.use(ApiServicePlugin);

const devListService = new DevListService(sessionService, apiService, store);
const devListServicePlugin = (V: typeof Vue): void => {
  V.prototype.$devListService = devListService;
};
Vue.use(devListServicePlugin);

const geolocationService = new geolocation(env.geolocation, devListService, sessionService);
const GeolocationServicePlugin = (V: typeof Vue): void => {
  V.prototype.$geolocationService = geolocationService;
};
Vue.use(GeolocationServicePlugin);

// Create User service
const userService = new UserService(i18n, apiService, sessionService, store);
const UserServicePlugin = (V: typeof Vue): void => {
  V.prototype.$userService = userService;
};
Vue.use(UserServicePlugin);

// Create Helki Node Management service
const helkiNMService = new helkiNodeManagement();
const helkiNMServicePlugin = (V: typeof Vue): void => {
  V.prototype.$helkiNMService = helkiNMService;
};
Vue.use(helkiNMServicePlugin);

// Create amplitude service
const amplitudeService = new AmplitudeService(env.amplitude, sessionService, userService);
const AmplitudeServicePlugin = (V: typeof Vue): void => {
  V.prototype.$amplitudeService = amplitudeService;
};
Vue.use(AmplitudeServicePlugin);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

export {
  sessionService,
  apiService,
  devListService,
  userService,
};
